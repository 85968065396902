import { graphql, PageProps } from "gatsby"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import tw from "twin.macro"
import { Layout } from "../components/atoms/Layout"
import { css } from "@emotion/react"
import { SEO } from "../components/atoms/SEO"
import Coupons from "../components/molecules/Coupons/Coupons"
import { Coupon } from "../components/molecules/Coupons/Coupons.d"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import { Brochure, ToyotaCare } from "../components/organisms/Series"
import ImageBlocks from "../components/organisms/Series/ImageBlock/ImageBlocks"
import { LanguageContext } from "../contexts/Language"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../contexts/Tealium"
import { LocationContext } from "../contexts/Location"
import useDealers from "../hooks/useDealers"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import Header from "../components/molecules/Heros/Header"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import { RetentionSalesEventHero } from "../components/molecules/RetentionSalesEventHero"
import { Find } from "../components/molecules/Find"
import moment from "moment"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"
import BlockContent from "../components/atoms/BlockContent"
import CtaBox from "../components/molecules/Heros/CtaBox"

const CouponsPage: React.FC<PageProps> = ({ data, location }: any) => {
  return (
    <Layout>
      <SEO
        url={location.href}
        title={data?.page.title}
        keywords={data?.page.seo?.keywords}
        schema={data?.page.seo?.schema}
        description={data?.page.seo?.description}
        canonicalPath={data?.page?.seo?.canonicalPath}
      />
      <SanitySections {...data} />
    </Layout>
  )
}

const CouponsSection: React.FC<{ coupons: Coupon[] }> = ({ coupons }) => {
  const today = moment()
  const activeCoupons = coupons
    ?.map((coupon: Coupon) => {
      const endDate = coupon.expires && moment(coupon.expires).endOf("day")
      const startDate = coupon.starts && moment(coupon.starts).startOf("day")
      if (today.isBetween(startDate, endDate)) {
        return coupon
      } else {
        return null
      }
    })
    ?.filter(coupon => coupon)

  const [filteredCoupons, setFilteredCoupons] = useState(activeCoupons)
  const [selectedFilter, setSelectedFilter] = useState("all")
  const { _ } = useContext(LanguageContext)
  const couponCategories = ["All", "Service", "Parts"]

  const tabAnimation = css`
    &:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:hover:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const focusAnimation = css`
    &:focus-visible:after {
      ${tw`absolute w-full rounded-full h-px bottom-0 left-0 bg-gray-900 origin-bottom-left transition-transform ease-out duration-300`}
      ${tw`h-px`}
    content: "";
      transform: scaleX(0);
    }
    @media (min-width: 1024px) {
      &:focus-visible:after {
        transform: scaleX(1);
        ${tw`origin-bottom-left bg-gray-900 h-px`}
      }
    }
  `
  const active = css`
    ${tw`font-semibold focus-visible:(border-transparent)`}
    &:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
    &:hover:after {
      ${tw`h-1 bg-red-400`}
    }
    &:focus-visible:after {
      transform: scaleX(1);
      ${tw`h-1 bg-red-400`}
    }
  `
  const [catData, setCatData] = useState(0)

  //Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const filterCoupons = (category: string) => {
    setSelectedFilter(category.toLowerCase())
    if (category.toLowerCase() === "all" || category.toLowerCase() === "todo") {
      setFilteredCoupons(activeCoupons)
      trackTealEvent({
        tealium_event: "refinement",
        refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Coupon Type|${category}`,
      })
    } else {
      setFilteredCoupons(
        activeCoupons?.filter(
          (coupon: any) =>
            coupon.category.toLowerCase() === category.toLowerCase()
        )
      )
      trackTealEvent({
        tealium_event: "refinement",
        refinement_value: `${tealPageData?.page_type}|${tealPageData?.page_name}|Coupon Type|${category}`,
      })
    }
  }

  //Tealium
  const { handleTealView } = useTealiumView()
  const [{ dealers }] = useContext(LocationContext)
  const pageload = useRef(true)
  const { getDealerInfo } = useDealers()
  const [isOutOfArea] = useTealiumNoZip()

  useEffect(() => {
    const dealerInfo = getDealerInfo()
    /* Fire only on inital page load, and not every time dealers is updated */
    if (isOutOfArea && pageload.current) {
      pageload.current = false
      handleTealView({
        page_name: "deals coupons",
        tealium_event: "parts_service_deals",
        page_type: "parts service",
        search_results: 0,
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
      })
    }

    if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        page_name: "deals coupons",
        tealium_event: "parts_service_deals",
        page_type: "parts service",
        search_results: dealers?.length,
        dealer_code: dealerInfo.dealerCodes,
        dealer_name: dealerInfo.dealerNames,
      })
    }
  }, [dealers, isOutOfArea])

  const couponCountLabel = useMemo(() => {
    switch (true) {
      case selectedFilter === "all":
        return `${
          filteredCoupons?.length !== 1
            ? _("Toyota Coupons")
            : _("Toyota Coupon")
        }`
      case selectedFilter === "service":
        return `${
          filteredCoupons?.length !== 1
            ? _("Toyota Service Coupons")
            : _("Toyota Service Coupon")
        }`
      case selectedFilter === "parts":
        return `${
          filteredCoupons?.length !== 1
            ? _("Toyota Parts Coupons")
            : _("Toyota Parts Coupon")
        }`
    }
  }, [selectedFilter, filteredCoupons?.length])

  return (
    <section aria-label="Coupon Section">
      <ul
        css={[
          tw`flex gap-6 overflow-x-scroll w-full px-4 my-2 scrollbar-hide py-1.5 justify-center`,
          tw`md:(justify-center)`,
        ]}
      >
        {couponCategories?.map((cat, index) => (
          <button
            onClick={() => filterCoupons(cat)}
            aria-selected={selectedFilter === cat.toLocaleLowerCase()}
            role="tab"
            css={[
              tabAnimation,
              focusAnimation,
              tw`cursor-pointer whitespace-nowrap inline-block relative py-2 mx-2`,
              selectedFilter === cat.toLocaleLowerCase() && active,
            ]}
            analytics-id={`coupon filter:coupon:${index + 1}`}
          >
            {_(cat)}
          </button>
        ))}
      </ul>
      <div css={[tw`max-w-7xl text-xs mx-auto px-8`]}>
        <div css={[tw`border-b pb-2`]}>
          <span css={[tw`font-semibold`]}> {filteredCoupons?.length} </span>
          {couponCountLabel}
        </div>
      </div>
      <Coupons
        coupons={filteredCoupons}
        showTitle={false}
        vertical={true}
        css={tw`relative z-50`}
      />
    </section>
  )
}

const SanitySections = (data: any): JSX.Element => {
  const retentionSalesEventData = data?.content?.find(
    (section: any) => section._type === "salesEventHeroPageBuilder"
  )?.salesEvent
  const retentionSalesEventHeroData = retentionSalesEventData?.salesEventActive
    ? retentionSalesEventData?.hero
    : null
  return (
    <>
      {data?.page?.content?.map((section: any) => {
        switch (section?._type) {
          case "header":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero {...retentionSalesEventHeroData} />
              )
            }
            return (
              <Header section={section} aria-label="Coupons Hero Section" />
            )
          case "partsAndServiceHero":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero {...retentionSalesEventHeroData} />
              )
            }
            return <PartAndServiceHero {...section} />
          case "imageWithText":
            return <ToyotaCare {...section} />
          case "imageBlocks":
            const imageBlocksData = section?.callouts
            return (
              <ImageBlocks
                imageBlocks={imageBlocksData}
                _key={"Image Blocks"}
                sectionSlug={{
                  current: "ImageBlocks",
                }}
              />
            )
          case "couponsLandingSection":
            return <CouponsSection coupons={section?.coupons} />
          case "dealersNearYouSection":
            return (
              <DealersNearYou
                type="default"
                {...section}
                ctaAnalyticsId="schedule service"
              />
            )
          case "imageBlocksWithDescriptionSection":
            return <ImageBlocksWithDescription section={section} />
          case "salesEventImageWithTextPageBuilder":
            if (!section?.salesEvent?.salesEventActive) return null
            return (
              <ToyotaCare
                {...section?.salesEvent?.imageWithText}
                imagePosition={section.imagePosition}
              />
            )
          case "generalContent":
            return (
              <div
                css={[
                  tw`max-w-desktop py-10 mx-auto text-lg pb-14 px-6`,
                  tw`sm:(px-8)`,
                  tw`lg:(px-10)`,
                ]}
              >
                <BlockContent data={section.contentBody} />
              </div>
            )
          case "brochure":
            return <Brochure {...section} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query CouponsPageQuery($id: String) {
    page: sanityPageCoupons(id: { eq: $id }) {
      id
      language
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      content {
        ...HeaderData
        ...CallToActionBoxData
        ...ImageBlocksData
        ...ToyotaCareData
        ...DealersNearYouData
        ... on SanityCouponsLandingSection {
          _type
          coupons {
            _id
            name
            expires
            offer
            image {
              ...SanityImageData
            }
            category
            type
            disclaimers {
              code
              disclaimer
            }
            starts
          }
        }
        ...ImageBlocksWithDescriptionData
        ...RetentionSalesEventImageWithTextData
        ...RetentionSalesEventHeroData
        ...PartsAndServiceHeroData
        ... on SanityGeneralContent {
          _type
          title
          contentBody: _rawBody(resolveReferences: { maxDepth: 5 })
        }
        ...BrochureData
      }
    }
  }
`

export default CouponsPage
